/*Pagination buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid #607d8b!important;
    background-color: #607d8b!important;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #607d8b), color-stop(100%, #607d8b))!important;
    background: -webkit-linear-gradient(top, #607d8b 0%, #607d8b 100%)!important;
    background: -moz-linear-gradient(top, #607d8b 0%, #607d8b 100%)!important;
    background: -ms-linear-gradient(top, #607d8b 0%, #607d8b 100%)!important;
    background: -o-linear-gradient(top, #607d8b 0%, #607d8b 100%)!important;
    background: linear-gradient(to bottom, #607d8b 0%, #607d8b 100%)!important;
}
